/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 cube2.glb --instance 
Files: cube2.glb [10.43MB] > /Users/jefstals/Documents/GitHub/tuinhuis-configurator/public/cube2-transformed.glb [7.26MB] (30%)
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Cubea(props) {
  const { nodes, materials } = useGLTF('/cube2-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes['#1'].geometry} material={materials.PaletteMaterial001} />
      <mesh geometry={nodes['#4'].geometry} material={materials['VXLWMAT#9']} />
      <mesh geometry={nodes['#9'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#12'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#13'].geometry} material={materials['VXLWMAT#13']} />
      <mesh geometry={nodes['#14'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#16'].geometry} material={materials['VXLWMAT#10']} />
      <mesh geometry={nodes['#28'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#57'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#74'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#98'].geometry} material={materials.PaletteMaterial002} />
      <mesh geometry={nodes['#99'].geometry} material={materials['VXLWMAT#2']} />
      <mesh geometry={nodes['#100'].geometry} material={materials['VXLWMAT#4']} />
      <mesh geometry={nodes['#265'].geometry} material={materials['VXLWMAT#22']} />
    </group>
  )
}

useGLTF.preload('/cube2-transformed.glb')
