import { OrbitControls, OrthographicCamera, PerspectiveCamera } from "@react-three/drei";

export const UrsemCamera = ({view}) => {

    switch(view){

        case 'TOP':
            return <>
                        <OrbitControls
              enableRotate={true} // Disable rotation for top view
              enablePan={true}
              enableZoom={true}
              maxPolarAngle={Math.PI / 2*-2} // Fix camera to top-down view
              minPolarAngle={Math.PI / 2*-2} // Limit vertical rotation
              maxAzimuthAngle={0} // Allow panning in the xz-plane
              minAzimuthAngle={-0}
              enableDamping={false}
              dampingFactor={0.05}
            />

            <OrthographicCamera
            makeDefault
            position={[0, 20, 0]} // Position directly above the scene
            zoom={50} // Zoom level for the orthographic camera
            near={1}
            far={100}
            onUpdate={(camera) => camera.lookAt(0, 0, 0)} // Ensure the camera looks directly down at the origin

          />


            </>

            break;
        default:
            return <>
             <PerspectiveCamera
                    makeDefault
                    position={[5, 20, 22]} // Initial position for the perspective view
                    
                    near={1}
                    far={100}
                    fov={75}
                    />
            <OrbitControls
              enableRotate={true} // Enable full rotation for 3D view
              enablePan={true}
              enableZoom={true}
              enableDamping={false}
              dampingFactor={0.05}
            />
           </>


    }



}