import { Box, Grid, Merged, OrbitControls, PerspectiveCamera, Plane, Stats, useGLTF } from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Instances, Model } from './autoinstance';
import { Cube } from './Cube';
import { Cubea } from './Cube2';
import { useEffect, useMemo, useRef, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';  // for ES6 imports
import { UrsemCamera } from './camera';
import { ToggleButton } from '@mui/material';
import { PiSolarPanelBold } from 'react-icons/pi';
import { RiGitPrDraftLine } from 'react-icons/ri';
import { Unit } from './unit';
import { ClippingMenu } from './clippingMenu';
import { HiOutlineCube } from "react-icons/hi";

import { TbTopologyRing3 } from "react-icons/tb";
import { get_material } from './materials';


const defaultFormData = {
    Aantal_X: 1,
    Aantal_Y: 1,
    Aantal_Z: 1,
}

export const Ursemgrid = ({formData = {defaultFormData}, updateSelectedUnit}) => {
    console.log(formData);

    const new_material = useMemo(() => { return get_material()},[]);

    const [boxSize, setBoxSize] = useState(null);
    const [view, setView] = useState('DEFAULT');
    const [selectedObject, setSelectedObject] = useState(null);
    const [hoveredObject, setHoveredObject] = useState(null);
    const originalMaterialsRef = useRef({}); // Ref to store original materials
    const prevObjectRef = useRef(null);
    const [clippingPlane, setClippingPlane] = useState(null);
    const [clippingLevel,setClippingLevel] = useState(100);
    const glRef = useRef();

    // Create clipping plane based on formData
    const dynamicClippingPlane = useMemo(() => {
        return new THREE.Plane(new THREE.Vector3(0, -1, 0), boxSize?.z ? ((boxSize.z * clippingLevel - boxSize.z / 2) * 0.001) : 100);
    }, [clippingLevel]);

    // Update the clipping plane in the renderer when formData.SP_X changes
    useEffect(() => {
        if (glRef.current) {
            glRef.current.clippingPlanes = [dynamicClippingPlane];  // Update the clipping plane
            glRef.current.localClippingEnabled = true;  // Enable local clipping
        }
    }, [dynamicClippingPlane]);



    
    useEffect(()=> {
        console.log(selectedObject)
        if(selectedObject?.userData){
        console.log(selectedObject.userData)
        updateSelectedUnit(selectedObject.userData)
    }else{
        updateSelectedUnit(null)
    }
    }, [selectedObject]    )

    const { gridSize, ...gridConfig } = {
        gridSize: [10.5, 10.5],
        cellSize: 0.5,
        cellThickness: 0.5,
        cellColor: "#aeaeae",
        sectionSize: 2,
        sectionThickness: 1,
        sectionColor: "#9b7ede",
        
        followCamera: false,
        infiniteGrid: true,
      };
    


      useEffect(() => {
        console.log("what? dit mag maar één keer!")
        // Load the GLTF model
        const loader = new GLTFLoader();


        loader.load('cube.glb', (gltf) => {
          const root = gltf.scene;

          const box = new THREE.Box3().setFromObject(root); // Calculate bounding box
          const size = box.getSize(new THREE.Vector3());
          const center = box.getCenter(new THREE.Vector3());
          console.log(size)
          console.log(center);
          

            

          setBoxSize(size); // Store the size in the state
        });
      }, []); // Run only once on mount
    

      

    

// Grid instellingen
const gridZ = formData.Aantal_X *1// diepte;
const gridX = formData.Aantal_Y *1// breedte;
const gridY = formData.Aantal_Z *1; // hoogte
const spacing = 0;  // Ruimte tussen de boxen



const Ren = () => {
    const { gl, camera, scene } = useThree();

    const gltf = useGLTF('/cube.glb'); 
    const gltf2 = useGLTF('/unit2.glb'); 

    return useMemo(() => {    
    
        const model = gltf.scene;
        const model2 = gltf2.scene;

// Material adjustments
Object.values(gltf.materials).forEach((material) => {
    if (material.isMeshStandardMaterial) {
      material.metalness = 0;
    }

    /*
    if(material.name == 'RGBffffff' || material.name == 'VXLWMAT#3'){
        //material.color.set(0xff0000);
        material = new_material;
        material.name = 'RGBffffff'
        
      }
        */

  });

      // Bereken de bounding box van het model
  const box = new THREE.Box3().setFromObject(model);
  const center = box.getCenter(new THREE.Vector3());

  // Centreer het model door de center-coördinaten af te trekken
  model.position.sub(center);
  const rot_x = -1*Math.PI / 2; 

  model.rotation.set(rot_x,0,0);
  model.scale.set(0.001,0.001,0.001)

  Object.values(gltf2.materials).forEach((material) => {
    if (material.isMeshStandardMaterial) {
      material.metalness = 0;
    }

    /*
    if(material.name == 'RGBffffff' || material.name == 'VXLWMAT#3'){
        //material.color.set(0xff0000);
        material = new_material;
        material.name = 'RGBffffff'
        
      }*/


  });
  model2.position.sub(center);
  model2.rotation.set(rot_x,0,0);
  model2.scale.set(0.001,0.001,0.001)
  
    //scene.add(model)

    if (!boxSize) return null;

    const cubes = [];

    console.log(boxSize)

    const boxWidth = boxSize.x - 100;
    const boxHeight = boxSize.z 
    const boxDepth = boxSize.y - 350;

    console.log(boxWidth);
    console.log(boxHeight);





       for (let i = 0; i < gridX; i++) {
                
        for (let j = 0; j < gridY && j < clippingLevel; j++) {
            for (let k = 0; k < gridZ; k++) {
                const u = formData.grid[i][j][k];
                if (u.type == 'NONE'){
                    continue;
                }
                const unit = u.type == 'A' ? model.clone() : model2.clone();


                //const unit = model.clone();
                unit.rotation.set(rot_x,0,0);
                unit.scale.set(0.001,0.001,0.001)
                unit.position.sub(center)
                unit.translateX((i * boxWidth )*0.001);
                unit.translateZ(j*boxHeight * 0.001 + boxHeight/2*0.001);
                unit.translateY((k*boxDepth )*-0.001);

                unit.traverse(o => {
                    if(o.isMesh){
                        /*
                    if(o.material.name == 'RGBffffff' || o.material.name == 'VXLWMAT#3'){
                        //material.color.set(0xff0000);
                        //o.material = new_material;
                        o.material.name = 'RGBffffff'
                        
                      }   
                        */                    
                    }
                })
     

                // not selected type
                /*
                if(        (selectedObject?.userData) &&          !(i == selectedObject.userData.grid.x && j == selectedObject.userData.grid.y  && k == selectedObject.userData.grid.z )){
                    unit.traverse(o => {

                        if(o.isMesh){



                            //o.material = o.material.clone();
                            //o.material.side = THREE.DoubleSide;
                            //o.material.transparent = true;
                            //o.material.opacity = 0.05;
                            //o.material.color = 0xff0000
                            

                        }
                    })

                }
                    */

                if(        (selectedObject?.userData) &&          (i == selectedObject.userData.grid.x && j == selectedObject.userData.grid.y  && k == selectedObject.userData.grid.z )){
                    unit.traverse(o => {
                        if(o.isMesh){
                            o.material = o.material.clone();
                            //o.material.side = THREE.DoubleSide;
                            o.material.transparent = true;
                            o.material.opacity = 0.55;
                            o.material.color.set(0xfff000)
                        }
                    })
                }


                //unit.position.set(center.x,center.y,center.z)
                

                //const model =<Cube rotation={[rot_x,0,0]} scale={[0.001,0.001,0.001]}  key={i+''+j+''+k} position={[(i * boxWidth )*-0.001,j*boxHeight * -0.001 - boxHeight/2*0.001 ,(k*boxDepth )*-0.001]}  />;
                //const model =<Unit rotation={[rot_x,0,0]} scale={[0.001,0.001,0.001]}  key={i+''+j+''+k} position={[(i * boxWidth )*-0.001,j*boxHeight * -0.001 - boxHeight/2*0.001 ,(k*boxDepth )*-0.001]}  />;
                const m =  <primitive key={"UNIT"+i+k+j}
                  object={unit}
                />
                cubes.push(m)
               


        
        }}}

    return cubes;

}, [formData])


}


const GridBoxes = () => {

        if (!boxSize) return null;

    const boxes = [];

    console.log(boxSize)

    const boxWidth = boxSize.x;
    const boxHeight = boxSize.z
    const boxDepth = boxSize.y;
       
       for (let i = 0; i < (gridX); i++) {
                
        for (let j = 0; j < (gridY) && j < clippingLevel; j++) {
            for (let k = 0; k < (gridZ); k++) {

                const u = formData.grid[i][j][k];
                if (u.type == 'NONE'){
                    continue;
                }

                boxes.push(<Box name="UNITBOX" userData={{ grid: {x: i , y: j , z: k }}}  args={[boxWidth*0.001, boxHeight*0.001, boxDepth*0.001]} key={i+''+j+''+k}  position={[(i * boxWidth)*0.001,j * boxHeight * 0.001 + boxHeight/2*0.001 ,(k*boxDepth)*0.001]} >
                    <meshStandardMaterial  attach="material" visible={false} color={0xFF0000} />
                  </Box>)
        
        }}}

    return boxes;

}









    const RaycastHandler = ({ updateSelectedObject, updateHoveredObject }) => {
        const { gl, camera, scene } = useThree();
        const raycaster = useRef(new THREE.Raycaster());
        const mouse = useRef(new THREE.Vector2());

        useEffect(() => {
            const handleClick = (event) => {
                mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
                raycaster.current.setFromCamera(mouse.current, camera);

                const objectsToIntersect = scene.children.filter(child => child.name === 'UNITBOX');
                const intersects = raycaster.current.intersectObjects(objectsToIntersect);

                if (intersects.length > 0) {
                    updateSelectedObject(intersects[0].object);
                }else{
                    updateSelectedObject(null)
                }
            };



            
            const handleMouseMove = (event) => {
            /*    
                mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
                raycaster.current.setFromCamera(mouse.current, camera);
            
                const objectsToIntersect = scene.children.filter(child => child.name === 'UNITBOX');
                const intersects = raycaster.current.intersectObjects(objectsToIntersect);
            
                const newHoveredObject = intersects.length > 0 ? intersects[0].object : null;
            
                // Only update if the hovered object has changed
                if (newHoveredObject !== hoveredObject) {
                    updateHoveredObject(newHoveredObject);
                }
                  */  

            }
    
            gl.domElement.addEventListener("click", handleClick);
            gl.domElement.addEventListener("mousemove", handleMouseMove);
            
            return () => {
                gl.domElement.removeEventListener("click", handleClick);
                gl.domElement.removeEventListener("mousemove", handleMouseMove);
            };
        }, [gl.domElement, camera]);

        return null;
    };
      
    const HighlightSelected = ({ object }) => {
        useEffect(() => {
            if (prevObjectRef.current) {
                prevObjectRef.current.traverse((child) => {
                    if (child.isMesh && originalMaterialsRef.current[child.uuid]) {
                        child.material.copy(originalMaterialsRef.current[child.uuid]); // Restore the original color
                    }
                });
            }

            if (object) {
                object.traverse((child) => {
                    if (child.isMesh) {
                        if (!originalMaterialsRef.current[child.uuid]) {
                            originalMaterialsRef.current[child.uuid] = child.material.clone(); // Store original color
                        }
                        child.material.visible = true // make visible!
                        child.material.color.set(0xFF0000);
                        child.material.opacity = 1
                    }
                });
                prevObjectRef.current = object;
            }
        }, [object]);

        return object ? <primitive object={new THREE.BoxHelper(object, 0xff00ff)} /> : null;
    };

    const HighlightHovered = ({ object }) => {
        /*
        useEffect(() => {
            if (object) {
                object.traverse((child) => {
                    if (child.isMesh) {
                        if (!originalMaterialsRef.current[child.uuid]) {
                            originalMaterialsRef.current[child.uuid] = child.material.color.clone(); // Store original color
                        }
                        child.material.color.set(0x00ff00); // Highlight color (green)
                    }
                });
            }
            
            // Revert color when the object is not hovered
            return () => {
                if (object) {
                    object.traverse((child) => {
                        if (child.isMesh && originalMaterialsRef.current[child.uuid]) {
                            child.material.color.copy(originalMaterialsRef.current[child.uuid]); // Restore original color
                        }
                    });
                }
            };
        }, [object]);
        return null;*/
        
    };
return (
    <>
    
    <div className="viewermenu">
    <div>

<ToggleButton
  sx={{marginLeft: "0px"}}
  selected={view == 'TOP'}
  value="selected"
  onChange={() => {setView('TOP')}}
  aria-label="visible">
   <TbTopologyRing3 />

</ToggleButton>


<ToggleButton 
  sx={{marginLeft: "10px"}}
  selected={view == 'DEFAULT'}
  value="selected"
  onChange={() => {setView('DEFAULT');}}
  aria-label="wireframe">
    <HiOutlineCube /> 
</ToggleButton>

{formData.Aantal_Z ? 
<ClippingMenu levels={formData.Aantal_Z*1 } setClippingLevel={setClippingLevel} /> : '' }

{clippingLevel ? clippingLevel : ''}

</div>
      </div>
    
      <Canvas
      gl={{logarithmicDepthBuffer: true, antialias: true}}
      onCreated={({ gl }) => {
        // Set the clipping planes globally on the renderer
        glRef.current = gl;  // Store gl in a ref so we can update it later
        setClippingPlane(dynamicClippingPlane);
      }}
      style={{ width: "100vw", height: "100vh" }}>
      <Grid args={gridSize} {...gridConfig} />
            
            {/*<Stats />*/}
            <PerspectiveCamera
              makeDefault
              position={[0, 10, 10]}
              near={0.1}
              far={100}
              fov={75}
            />
            
            
            <UrsemCamera view={view} />
            
            
            <RaycastHandler 
              updateSelectedObject={setSelectedObject} 
              updateHoveredObject={setHoveredObject} />    
             {/* <HighlightSelected object={selectedObject} />
                <HighlightHovered object={hoveredObject} /> */}
            <Ren />
            <GridBoxes />
        <axesHelper
          args={[5, 5, 5]} />
 <ambientLight intensity={2} />
      </Canvas>
      </>
      );

}

useGLTF.preload('/cube.glb')
useGLTF.preload('/unit2.glb')
