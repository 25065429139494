import logo from './logo.svg';
import './App.css';
import { TuinhuisConfigurator } from './TuinhuisConfigurator';
import { Ursemgrid } from './ursem/grid';
import { UrsemForm } from './ursem/ursemForm';
import { useState } from 'react';

function App() {
  const [formData, setFormData ] = useState();
  const [selectedUnit, updateSelectedUnit] = useState();

  return (

<div className="container">

<div className="form-container">
<UrsemForm updateFormData={setFormData} selectedUnit={selectedUnit} />
</div>

    <div className='viewer-container'>
    <Ursemgrid formData={formData} updateSelectedUnit={updateSelectedUnit} />



    </div>
      </div>



  
  );
}

export default App;
