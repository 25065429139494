import * as THREE from 'three';

const formData = {cladding : {filename: 'Bricks066_1K-JPG'}}
const w = 900;
const h = 500;

    export const get_material = () => {
    
    // Step 6: Load textures and create materials
    
    console.log(`textures/${formData.cladding.filename}_Color.jpg`)
    const textureLoader = new THREE.TextureLoader();

    const claddingTexture = textureLoader.load(`textures/${formData.cladding.filename}_Color.jpg`);
    claddingTexture.wrapS = THREE.RepeatWrapping;
    claddingTexture.wrapT = THREE.RepeatWrapping;
    claddingTexture.colorSpace = THREE.SRGBColorSpace;
    //claddingTexture.repeat.set( formData.cladding.size.x*5, formData.cladding.size.y*5  );
    const TEXTURE_SIZE = 750;
    claddingTexture.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);
    claddingTexture.needsUpdate = true;

    const claddingNormalMap = textureLoader.load(`textures/${formData.cladding.filename}_NormalGL.jpg`);
    claddingNormalMap.wrapS = THREE.RepeatWrapping;
    claddingNormalMap.wrapT = THREE.RepeatWrapping;
    claddingNormalMap.colorSpace = THREE.SRGBColorSpace;
    claddingNormalMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);

    //claddingNormalMap.repeat.set(  formData.cladding.size.x*5, formData.cladding.size.y*5  );


    const claddingRoughnessMap = textureLoader.load(`textures/${formData.cladding.filename}_Roughness.jpg`);
    claddingRoughnessMap.colorSpace = THREE.SRGBColorSpace;
    claddingRoughnessMap.wrapS = THREE.RepeatWrapping;
    claddingRoughnessMap.wrapT = THREE.RepeatWrapping;
    //claddingRoughnessMap.repeat.set(  formData.cladding.size.x*5, formData.cladding.size.y*5  );
    claddingRoughnessMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);



    const claddingMaterial = new THREE.MeshStandardMaterial({
        map: claddingTexture,
        normalMap: claddingNormalMap,
        roughnessMap: claddingRoughnessMap,
        roughness: 1,
        side: THREE.DoubleSide,
        //color: 0xff0000,
        metalness: 0

    });

    return claddingMaterial;

}