import React, { useMemo, useRef } from 'react';
import { useGraph } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js';



export const Unit = (props) => {
    const group = useRef()

  const { scene, materials, animations } = useGLTF('/cube.glb');
  const clone =  SkeletonUtils.clone(scene);
  const { nodes } = useGraph(clone)

  console.log(props)

  return (
    <group ref={group} >
      <primitive
      {...props}
        object={nodes}
      />
    </group>
  );
}

useGLTF.preload('/cube.glb')
