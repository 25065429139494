import React from 'react';
import { Canvas } from '@react-three/fiber';
import { BoxGeometry, MeshBasicMaterial, Mesh, Line, LineBasicMaterial, Vector3, BufferGeometry } from 'three';

// Custom CAD Pointer Component
export const CADPointer = ({ position }) => {
  // Create a line material
  const lineMaterial = new LineBasicMaterial({ color: 'blue' });

  // Function to create a centered line
  const createLineGeometry = (start, end) => {
    const geometry = new BufferGeometry().setFromPoints([start, end]);
    return geometry;
  };

  // Create horizontal and vertical lines
  const horizontalLineGeometry = createLineGeometry(new Vector3(-0.25, 0, 0), new Vector3(0.25, 0, 0));
  const verticalLineGeometry = createLineGeometry(new Vector3(0, -0.25, 0), new Vector3(0, 0.25, 0));

  return (
    <>
      <line geometry={horizontalLineGeometry} material={lineMaterial} position={[position[0], position[1], 0.2]} />
      <line geometry={verticalLineGeometry} material={lineMaterial} position={[position[0], position[1], 0.2]} />
    </>
  );
}