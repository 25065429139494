/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 cube.glb 
*/

import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';


export const Cube = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('/cube.glb');
  const modelRef = useRef();

  // Iterate through all materials and set the metalness to 1
  Object.values(materials).forEach((material) => {
    if (material.isMeshStandardMaterial) {
      material.metalness = 0;
      //material.wireframe = true
    }

    /*
    if(material.name == 'VXLWMAT#666' || material.name == 'VXLWMAT#3333'){
      material.visible = false;
    }*/
  });

    // Create the axes helper
    
    useEffect(() => {

      /*
    // Calculate the bounding box
    const box = new THREE.Box3().setFromObject(modelRef.current);
    const center = box.getCenter(new THREE.Vector3());
    
    // Center the model
    modelRef.current.position.set(-center.x, -center.y, -center.z);
*/

/*
      const axesHelper = new THREE.AxesHelper(5000); // Length of axes
      modelRef.current.add(axesHelper); // Add axes helper to the model
      */
    }, []);


    // Gebruik `useImperativeHandle` om de `modelRef` toegankelijk te maken via de parent component
  useImperativeHandle(ref, () => ({
    getModel: () => modelRef.current,
  }));


  return (
    <group visible={false} ref={modelRef}  {...props} dispose={null}>
      <mesh geometry={nodes['#1'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#2'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#3'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#4'].geometry} material={materials['VXLWMAT#9']} />
      <mesh geometry={nodes['#6'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#7'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#8'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#9'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#10'].geometry} material={materials['VXLWMAT#12']} />
      <mesh geometry={nodes['#11'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#12'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#13'].geometry} material={materials['VXLWMAT#13']} />
      <mesh geometry={nodes['#14'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#15'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#16'].geometry} material={materials['VXLWMAT#10']} />
      <mesh geometry={nodes['#17'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#18'].geometry} material={materials['VXLWMAT#12']} />
      <mesh geometry={nodes['#19'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#20'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#21'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#22'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#24'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#26'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#27'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#28'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#29'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#30'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#31'].geometry} material={materials['VXLWMAT#13']} />
      <mesh geometry={nodes['#32'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#33'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#34'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#35'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#36'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#37'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#38'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#39'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#40'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#41'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#42'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#43'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#44'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#45'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#46'].geometry} material={materials['VXLWMAT#9']} />
      <mesh geometry={nodes['#47'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#48'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#49'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#50'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#51'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#52'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#53'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#54'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#55'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#56'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#57'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#58'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#59'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#60'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#61'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#63'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#64'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#65'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#67'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#68'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#69'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#70'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#71'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#72'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#73'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#74'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#75'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#76'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#77'].geometry} material={materials['VXLWMAT#10']} />
      <mesh geometry={nodes['#78'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#79'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#80'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#81'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#82'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#83'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#84'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#85'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#86'].geometry} material={materials['VXLWMAT#10']} />
      <mesh geometry={nodes['#87'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#88'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#89'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#90'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#91'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#92'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#93'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#94'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#95'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#96'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#97'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#99'].geometry} material={materials['VXLWMAT#2']} />
      <mesh geometry={nodes['#100'].geometry} material={materials['VXLWMAT#4']} />
      <mesh geometry={nodes['#101'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#102'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#103'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#104'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#105'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#106'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#107'].geometry} material={materials['VXLWMAT#2']} />
      <mesh geometry={nodes['#108'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#109'].geometry} material={materials['VXLWMAT#4']} />
      <mesh geometry={nodes['#110'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#112'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#113'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#114'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#115'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#116'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#117'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#118'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#119'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#120'].geometry} material={materials.RGBffffff} />
      <mesh geometry={nodes['#121'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#122'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#123'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#124'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#125'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#126'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#127'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#128'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#129'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#130'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#131'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#132'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#133'].geometry} material={materials['VXLWMAT#10']} />
      <mesh geometry={nodes['#134'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#136'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#137'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#138'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#139'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#140'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#141'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#142'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#143'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#144'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#145'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#146'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#147'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#148'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#149'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#150'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#151'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#152'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#153'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#154'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#155'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#156'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#157'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#158'].geometry} material={materials['VXLWMAT#9']} />
      <mesh geometry={nodes['#159'].geometry} material={materials['VXLWMAT#13']} />
      <mesh geometry={nodes['#160'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#161'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#162'].geometry} material={materials['VXLWMAT#17']} />
      <mesh geometry={nodes['#163'].geometry} material={materials['VXLWMAT#12']} />
      <mesh geometry={nodes['#164'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#165'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#166'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#167'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#168'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#169'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#170'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#171'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#172'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#173'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#174'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#175'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#176'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#177'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#178'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#179'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#180'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#181'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#182'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#183'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#184'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#185'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#186'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#187'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#188'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#189'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#190'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#191'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#192'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#193'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#194'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#195'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#197'].geometry} material={materials['VXLWMAT#2']} />
      <mesh geometry={nodes['#198'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#199'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#200'].geometry} material={materials['VXLWMAT#12']} />
      <mesh geometry={nodes['#201'].geometry} material={materials['VXLWMAT#4']} />
      <mesh geometry={nodes['#202'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#203'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#204'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#205'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#206'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#207'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#208'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#209'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#210'].geometry} material={materials['VXLWMAT#11']} />
      <mesh geometry={nodes['#211'].geometry} material={materials['VXLWMAT#13']} />
      <mesh geometry={nodes['#212'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#213'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#214'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#215'].geometry} material={materials['VXLWMAT#9']} />
      <mesh geometry={nodes['#216'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#217'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#218'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#219'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#220'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#221'].geometry} material={materials['VXLWMAT#3']} />
      <mesh geometry={nodes['#222'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#223'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#224'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#225'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#226'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#227'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#228'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#229'].geometry} material={materials.RGBffffff} />
      <mesh geometry={nodes['#230'].geometry} material={materials['VXLWMAT#7']} />
      <mesh geometry={nodes['#231'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#232'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#233'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#234'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#235'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#236'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#237'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#238'].geometry} material={materials['VXLWMAT#21']} />
      <mesh geometry={nodes['#239'].geometry} material={materials['VXLWMAT#8']} />
      <mesh geometry={nodes['#240'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#241'].geometry} material={materials['VXLWMAT#18']} />
      <mesh geometry={nodes['#242'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#243'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#244'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#245'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#246'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#247'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#248'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#249'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#250'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#251'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#252'].geometry} material={materials['VXLWMAT#14']} />
      <mesh geometry={nodes['#253'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#254'].geometry} material={materials['VXLWMAT#4']} />
      <mesh geometry={nodes['#255'].geometry} material={materials['VXLWMAT#2']} />
      <mesh geometry={nodes['#258'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#259'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#260'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#261'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#262'].geometry} material={materials['VXLWMAT#16']} />
      <mesh geometry={nodes['#263'].geometry} material={materials['VXLWMAT#1']} />
      <mesh geometry={nodes['#264'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#265'].geometry} material={materials['VXLWMAT#22']} />
      <mesh geometry={nodes['#266'].geometry} material={materials.RGB91daff} />
      <mesh geometry={nodes['#267'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#268'].geometry} material={materials['VXLWMAT#5']} />
      <mesh geometry={nodes['#269'].geometry} material={materials['VXLWMAT#22']} />
      <mesh geometry={nodes['#270'].geometry} material={materials['VXLWMAT#6']} />
      <mesh geometry={nodes['#5'].geometry} material={materials['VXLWMAT#111']} />
      <mesh geometry={nodes['#5_1'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#5_2'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#23'].geometry} material={materials['VXLWMAT#111']} />
      <mesh geometry={nodes['#23_1'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#23_2'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#25'].geometry} material={materials['VXLWMAT#111']} />
      <mesh geometry={nodes['#25_1'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#25_2'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#62'].geometry} material={materials['VXLWMAT#19']} />
      <mesh geometry={nodes['#62_1'].geometry} material={materials['VXLWMAT#20']} />
      <mesh geometry={nodes['#66'].geometry} material={materials['VXLWMAT#19']} />
      <mesh geometry={nodes['#66_1'].geometry} material={materials['VXLWMAT#20']} />
      <mesh geometry={nodes['#98'].geometry} material={materials['VXLWMAT#31']} />
      <mesh geometry={nodes['#98_1'].geometry} material={materials['VXLWMAT#25']} />
      <mesh geometry={nodes['#98_2'].geometry} material={materials['VXLWMAT#32']} />
      <mesh geometry={nodes['#111'].geometry} material={materials['VXLWMAT#111']} />
      <mesh geometry={nodes['#111_1'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#111_2'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#135'].geometry} material={materials['VXLWMAT#111']} />
      <mesh geometry={nodes['#135_1'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#135_2'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#196'].geometry} material={materials.RGBff} />
      <mesh geometry={nodes['#196_1'].geometry} material={materials['VXLWMAT#15']} />
      <mesh geometry={nodes['#196_2'].geometry} material={materials['VXLWMAT#25']} />
      <mesh geometry={nodes['#196_3'].geometry} material={materials['VXLWMAT#34']} />
      <mesh geometry={nodes['#196_4'].geometry} material={materials.RGBb3b3b3} />
      <mesh geometry={nodes['#196_5'].geometry} material={materials['VXLWMAT#33']} />
      <mesh geometry={nodes['#256'].geometry} material={materials['VXLWMAT#19']} />
      <mesh geometry={nodes['#256_1'].geometry} material={materials['VXLWMAT#20']} />
      <mesh geometry={nodes['#257'].geometry} material={materials['VXLWMAT#19']} />
      <mesh geometry={nodes['#257_1'].geometry} material={materials['VXLWMAT#20']} />
    </group>
  )
});

useGLTF.preload('/cube.glb')
