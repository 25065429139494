import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Divider, IconButton } from '@mui/material';
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { FaRegFilePdf } from "react-icons/fa6";
import { HiCubeTransparent } from "react-icons/hi";
import { LuFileBox } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";

const StyledMenu = styled((props) => (

  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 0,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
    margin: '6px 0px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const ClippingMenu = ({levels,setClippingLevel}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };


    const handleClose = (level) => {
        
        if(typeof level === 'number'){
            setClippingLevel(level)
        }else{
            setClippingLevel(100)

        }

        setAnchorEl(null);
  };

  console.log(levels)

  return (
<>      <IconButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        sx={{marginLeft: '10px'}}
        onClick={handleClick}
        
      >
        <RiScissorsCutLine />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        
        onClose={handleClose}
      >

        {Array.from(Array(levels*1)).map((i,j) => (

            <MenuItem onClick={() => handleClose(j+1)} disableRipple>
            {j+1}
            </MenuItem>

        ))}

      </StyledMenu>
    </>
  );
}
