import React, { memo, useEffect, useRef } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  AccumulativeShadows,
  Environment,
  Line,
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  RandomizedLight,
  useGLTF,
} from "@react-three/drei";
import { Box } from "@react-three/drei";
import { useState } from "react";
import { OrbitControls, Grid } from "@react-three/drei";
import { BoxHelper, Vector3 } from "three";
import { CADPointer } from "./pointer";
import { WallMaterial } from "./texture";
import { Door, Door2, Model } from "./Model";
import { Vector2, Raycaster } from "three";
import * as THREE from 'three';
import { CSG } from 'three-csg-ts';
import { BufferGeometryUtils } from 'three-stdlib';

const corner_points = [];

const Scene = ({ mode }) => {
  const { gl, size, camera, scene } = useThree();
  const [points, setPoints] = useState(corner_points);
  const [snappedPosition, setSnappedPosition] = useState(new Vector3());
  const snap_grid_size = 0.5;

  const get_coordinates = (event) => {
    const { clientX, clientY } = event;

    // Convert mouse position to normalized device coordinates (NDC)
    const x = (clientX / size.width) * 2 - 1;
    const y = -(clientY / size.height) * 2 + 1;

    // Convert NDC to world coordinates
    const worldPosition = {
      x: (x * (camera.right - camera.left)) / 2 / 100,
      y: (y * (camera.top - camera.bottom)) / 2 / 100,
      z: 0, // Top-down view, so Z is constant
    };

    return worldPosition;
  };

  const snapToGrid = (position, gridSize) => {
    return {
      x: Math.round(position.x / gridSize) * gridSize,
      y: Math.round(position.y / gridSize) * gridSize,
    };
  };

  const handleMouseMove = (event) => {
    const cor = get_coordinates(event);
    const snappedPosition = snapToGrid(cor, snap_grid_size);
    setSnappedPosition(snappedPosition);
  };

  /*
    useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);
*/

  const handleClick = (event) => {
    // only draw in draw-mode
    if (mode !== "drawing") return;
    const coordinates = snappedPosition;
    setPoints([...points, coordinates]);
  };

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[0, 0, 10]} />

        

      {points.map((point, index) => {
        if (index === 0) return null; // Skip the first point since there's no previous point

        const previousPoint = points[index - 1];
        const dx = point.x - previousPoint.x;
        const dy = point.y - previousPoint.y;
        const wall_height = 3;

        // Calculate the position and rotation of the wall
        const position = [
          previousPoint.x + dx / 2,
          previousPoint.y + dy / 2,
          wall_height / 2,
        ];
        const length = Math.sqrt(dx * dx + dy * dy);
        const angle = Math.atan2(dy, dx);

        return (
          <mesh
            castShadow
            position={position}
            rotation={[0, 0, angle]}
            key={index}
          >
            <boxGeometry args={[length, 0.1, wall_height]} />
            <WallMaterial width={length} height={wall_height} />
          </mesh>
        );
      })}



      <mesh
        position={[0, 0, -1]}
        onPointerMove={handleMouseMove}
        onClick={handleClick}
      >
        <planeGeometry args={[25, 25]} />
        <meshStandardMaterial color="orange" transparent="true" opacity={0} />
      </mesh>

      <CADPointer
        position={[snappedPosition.x, snappedPosition.y, 0]}
      ></CADPointer>
     
    </>
  );
};

const Shadows = memo(() => (
  <AccumulativeShadows
    temporal
    frames={100}
    color="#9d4b4b"
    colorBlend={0.5}
    alphaTest={0.9}
    scale={20}
  >
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
));

const RaycastHandler = ({updateSelectedObject}) => {
  const { gl, camera, scene } = useThree();
  const raycaster = useRef(new Raycaster());
  const mouse = useRef(new Vector2());

  const handleClick = (event) => {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components.
    mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update the raycaster based on the camera and mouse position.
    raycaster.current.setFromCamera(mouse.current, camera);

    // Get the objects that intersect with the ray.
    // Filter out BoxHelper objects before intersecting
    const objectsToIntersect = scene.children.filter(
        (child) => !(child instanceof BoxHelper ) 
      );
    const intersects = raycaster.current.intersectObjects(objectsToIntersect);

    if (intersects.length > 0) {
      console.log("Object clicked:", intersects[0].object);
      updateSelectedObject(intersects[0].object);
    }
  };

  useFrame(() => {
    // Attach the click event listener to the renderer's DOM element.
    gl.domElement.addEventListener("click", handleClick);

    // Clean up the event listener on unmount.
    return () => {
      gl.domElement.removeEventListener("click", handleClick);
    };
  });

  return null;
};


const BoundingBox = ({ object }) => {
    const helperRef = useRef();
  
    useFrame(() => {
      if (helperRef.current && object) {
        helperRef.current.update();
      }
    });
  

    
    return (
      object && (
        <primitive object={new BoxHelper(object, 0xff0000)} ref={helperRef} />
      )
    );
  }
  
export const TuinhuisConfigurator = () => {
  const [mode, setMode] = useState("drawing"); // 'drawing' or '3d'
  const [selectedObject, updateSelectedObject] = useState(null);
  const wallRef = useRef();

  const { gridSize, ...gridConfig } = {
    gridSize: [10.5, 10.5],
    cellSize: 0.5,
    cellThickness: 0.5,
    cellColor: "#aeaeae",
    sectionSize: 2,
    sectionThickness: 1.5,
    sectionColor: "#9b7ede",
    fadeDistance: 35,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
  };

  const wallSize = {x: 3, y:0.1, z: 15};
  const [windowMesh, setWindowMesh] = useState(null);
  const raam = useGLTF("/models/Door2.glb");
  
  //const wallGeometry = new THREE.BoxGeometry(wallSize.x, wallSize.y, wallSize.z);

    const addWindow = () => {


        

        const windowObj = raam.scene.clone();
        
        windowObj.rotation.set(Math.PI / 2, 0, 0);
        console.log(selectedObject.position)
        windowObj.position.set(selectedObject.position.x, selectedObject.position.y, selectedObject.position.z );
        // Get the bounding box of the window
      const bbox = new THREE.Box3().setFromObject(windowObj);
      const windowSize = new THREE.Vector3();
      const windowPosition = new THREE.Vector3();
      bbox.getSize(windowSize);
      bbox.getCenter(windowPosition)

      console.log(windowPosition);

      // Create a window box geometry with the size of the bounding box
      const windowGeometry = new THREE.BoxGeometry(windowSize.x, windowSize.y, windowSize.z);
      const windowMesh = new THREE.Mesh(windowGeometry);
      windowMesh.position.set(windowPosition.x, windowPosition.y, windowPosition.z);
      //windowMesh.position.sub(windowPosition);
      //windowObj.position.sub(windowPosition)

      // Perform the boolean operation (subtract window hole from the wall)
      const wallMesh = selectedObject;
      const csgWall = CSG.fromMesh(wallMesh);
      const csgWindow = CSG.fromMesh(windowMesh);
      const subtractedCSG = csgWall.subtract(csgWindow);

// Convert the CSG result back to a mesh and get its geometry
const subtractedWallMesh = CSG.toMesh(subtractedCSG, wallMesh.matrix, wallMesh.material);
      
// Update the wall mesh's geometry with the result
selectedObject.geometry = subtractedWallMesh.geometry;
selectedObject.geometry.computeVertexNormals(); // Ensure smooth shading

// Store window for rendering later
setWindowMesh(windowObj);

    }


  return (
    <>
      <button
        style={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}
        onClick={() => setMode(mode === "drawing" ? "3d" : "drawing")}
      >
        {mode === "drawing" ? "Switch to 3D View" : "Switch to Drawing Mode"}
      </button>

      <button
        style={{ position: "absolute", top: 50, left: 10, zIndex: 1 }}
        onClick={addWindow}
      >
        Add window to selected wall
      </button>



      <Canvas shadows style={{ width: "100vw", height: "100vh" }}>
        <Grid args={gridSize} {...gridConfig} rotation={[Math.PI / 2, 0, 0]} />


        <group>
      
      {/* Render the window */}
      {windowMesh && <primitive object={windowMesh} />}
    </group>

        {mode === "drawing" ? (
          <>
            <OrthographicCamera
              up={[0, 0, 1]}
              makeDefault
              position={[0, 0, 10]}
              zoom={100}
            />
          </>
        ) : (
          <>
            <OrbitControls />
            <RaycastHandler updateSelectedObject={updateSelectedObject} />
            <BoundingBox object={selectedObject} />

            <Plane
              receiveShadow
              position={[0, 0, 0.1]}
              args={[100, 100]} // Size of the plane
            >
              <shadowMaterial attach="material" opacity={0.5} />
            </Plane>

            <PerspectiveCamera
              up={[0, 0, 1]}
              makeDefault
              position={[0, 0, 10]}
              fov={75}
            />
          </>
        )}

        <Scene mode={mode} />
        <axesHelper
          args={[2, 2, 2]}
          setColors={{ xAxisColor: "black", yAxisColor: "white" }}
        />

        <directionalLight
          castShadow // Enable casting shadows
          intensity={1.3} // Brightness of the light
          position={[5, 0, 10]} // Position of the light in the scene
          shadow-mapSize-width={1024} // Shadow quality
          shadow-mapSize-height={1024}
          shadow-camera-far={50} // Shadow camera settings
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />

        {/* Ambient light for softer shadows */}
        <ambientLight intensity={0.3} />
      </Canvas>
    </>
  );
};
