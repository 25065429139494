import * as THREE from "three";
import { roughness } from "three/webgpu";

const textureLoader = new THREE.TextureLoader();
const cladding_name = 'Bricks035_1K-JPG';
const claddingTexture = textureLoader.load(`textures/${cladding_name}_Color.jpg`);
const claddingNormalMap = textureLoader.load(`textures/${cladding_name}_NormalGL.jpg`);
const claddingRoughnessMap = textureLoader.load(`textures/${cladding_name}_Roughness.jpg`);

claddingTexture.wrapS = THREE.RepeatWrapping;
claddingTexture.wrapT = THREE.RepeatWrapping;
claddingTexture.colorSpace = THREE.SRGBColorSpace;

claddingNormalMap.wrapS = THREE.RepeatWrapping;
claddingNormalMap.wrapT = THREE.RepeatWrapping;
claddingNormalMap.colorSpace = THREE.SRGBColorSpace;

claddingRoughnessMap.colorSpace = THREE.SRGBColorSpace;
claddingRoughnessMap.wrapS = THREE.RepeatWrapping;
claddingRoughnessMap.wrapT = THREE.RepeatWrapping;

export const WallMaterial = ({width, height}) => {
    const w = width;
    const h = height;
   
    const TEXTURE_SIZE = 2.5
    claddingTexture.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);
    claddingTexture.needsUpdate = true;
    claddingNormalMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);
    claddingRoughnessMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);


    return       <meshStandardMaterial map={claddingTexture} normalMap={claddingNormalMap} roughnessMap={ claddingRoughnessMap}  roughness={1} />

} 