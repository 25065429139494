import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Chip,  useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { TbFileDownload } from "react-icons/tb";
import { FaSpinner } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { IoIosAddCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  
const projects = [];

export const LibraryDialog = ({onClose, selectedValue, open, projects }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const headers = ['Title','Klant','Order nummer','Status','VXZ file','Date']
    
    const [rows, updateRows] = React.useState([]);

      

  console.log("open projectLogDialog")

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };


  return (
    <Dialog fullWidth='xl' maxWidth='xl' sx={{padding: '10px'}} onClose={handleClose} open={open}>
      <DialogTitle>Unit bibliotheek</DialogTitle>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>

        {[0,1,2,3,4,5].map(() => (

            <Grid item xs={6}>
            <div className='section'>
                <h3>UNIT A1001&nbsp;&nbsp;<IoIosAddCircleOutline />&nbsp;&nbsp;<IoSettingsOutline /></h3>
                <img src='' />
            </div>
            </Grid>

        ))}
</Grid>
    </Dialog>
  );
}
